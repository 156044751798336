#centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#appleButton {
  display: 'inline-block';
  overflow: 'hidden';
  border-radius: '13px';
  width: '250px';
  height: '83px';
}

#appleButton img {
  border-radius: '13px';
  width: '250px'; 
  height: '83px';
}

#googleButton {
  height: '60px';
}

#storeButtons {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}